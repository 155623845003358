import styled from 'styled-components'
import {NavLink as Link} from 'react-router-dom'
import {FaCompass} from 'react-icons/fa'

export const Nav = styled.nav`
background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent')};
   height: 80px;
   margin-top: 0px;
   display: flex;
   justify-content: center;
   font-weight: 700; 
   position: sticky;
   top:0;
   z-index: 998;

   &.active {
       background: #000;
   }
`
export const NavLink = styled(Link)`
    color: #fff;
    font-size: 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    @media screen and (max-width: 400px){
        position: absolute;
        top: 10px;
        left: 25px;
    }
`
export const NavIcon = styled.div`
    display: block;
    position:absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: #fff;

    p {
        transform: translate(-175%, 100%);
        font-weight: bold;
    }
`

export const Bars = styled(FaCompass)`
    font-size: 2rem;
    transform: translate(-50%, 15%);
`