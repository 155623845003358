import product1 from '../../images/illustration.png'
import product2 from '../../images/west-india-hoodie.jpg'
import product3 from '../../images/headphones.jpg'


export const productData = [
    {
        img: product1,
        alt: 'Illustration',
        name: 'Started from scratch',
        desc: 'Our ideas start from the sketchbook and evolve from there'
        
    },
    {
        img: product2,
        alt: 'Apparel',
        name: 'For everyday life',
        desc: 'We make apparel for everyday wear and we wear what we sell'
        
    },
    {
        img: product3,
        alt: 'Headphones',
        name: 'Music inspired',
        desc: 'Our designs come from positive vibes and a steady supply of reggae'
        
    }
]


