import React from 'react'
import { NavHashLink as Link } from 'react-router-hash-link'
import {
    FaFacebook,
    FaInstagram,
    FaTwitter
    
} from 'react-icons/fa'
import {
    FooterContainer,
    FooterWrap,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcons,
    SocialIconLink
} from './FooterElements'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo><Link smooth to='#home' style={{textDecoration:"none", color: "#fff"}}>West India Design Co.</Link></SocialLogo>
                        <SocialIcons>
                            <SocialIconLink href='//www.facebook.com/westindiadesignco/' target="_blank" aria-label="Facebook" rel="noopener noreferrer">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href='//www.instagram.com/westindiadesigncompany/' target="_blank" aria-label="Instagram" rel="noopener noreferrer">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href='//twitter.com/WestIndiaDesign' target="_blank" aria-label="Twitter" rel="noopener noreferrer">
                                <FaTwitter />
                            </SocialIconLink>
                            
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
