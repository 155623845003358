import React from 'react'
import { AboutContainer, AboutHeading, AboutWrapper } from './AboutElements'

const About = () => {
    return (
        <AboutContainer id='about'>
            <AboutHeading>Who we are</AboutHeading>
            <AboutWrapper>
                <p>The West India Design Company
was created as a collective of talented individuals in the fields of art, design, technology, and music. This collective began as artists from Trinidad, Puerto Rico, Jamaica, and St. Thomas, V.I. The culmination of different cultures was the basis behind developing a business with a different approach to art, design, and interactive media.

</p><p>Even as a business, we’ve held on to our principles that we began with. Those include educating the public about the importance and uses of art and technology. As technology and media developed, the projects we produced changed. We became programmers as well as designers combining the visual with the functional. We started with paper and canvas. We now produce apparel designs in addition to the artwork on paper and canvas.

</p><p>At The West India Design Company, our mission is to introduce imagination to our customers. We believe in the next idea that can change the world or the next success story being a click or  a digital search away.

As artists, we started in traditional media. Print is where we honed our skills in graphic design and knowledge in typography to communicate with new perspectives in each design. We believe in being an integral part in the world of art and design.

</p><p>The World of Digital Design is constantly changing. As designers, we adapt then create new ways of communicating our skills. This is what we are about. Thinking outside the box and reinventing the box itself.</p>
            </AboutWrapper>
        </AboutContainer>
    )
}

export default About
