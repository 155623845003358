import React from 'react'

import Hero from '../components/Hero';
import Products from '../components/Products';
import { productData } from '../components/Products/data';
import Feature from '../components/Feature';
import Footer from '../components/Footer';
import Shop from '../components/Shop';
import About from '../components/About';

const Home = () => {
    return (
        
      <>
      <Hero />
      <Products heading='What we do' data={productData} />
      <Shop />
      <Feature />
      <About />
      
      <Footer />
      </>
    )
}

export default Home
