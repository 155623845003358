import React from 'react'
import { NavHashLink as Link } from 'react-router-hash-link'
import {FeatureContainer, FeatureButton} from './FeatureElements'

const Feature = () => {
    return (
        <FeatureContainer>
            <h1>Trend set never trend follow</h1>
            <p>Creativity in every design we produce</p>
            <FeatureButton><Link smooth to='#about' style={{textDecoration:"none", color: "#fff"}}>Who we are</Link></FeatureButton>
        </FeatureContainer>
    )
}
export default Feature