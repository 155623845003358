import React from 'react';
import { GlobalStyle } from './globalStyles';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './pages'
import ScrollToTop from './components/ScrollToTop';





function App() {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyle />
        <Switch>
        
        <Route path='/' exact component={Home} />
        
        </Switch>
      
      
      
      
    </Router>
  );
}

export default App;
