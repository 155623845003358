import React from 'react'
import Iframe from 'react-iframe'
import { ShopContainer, ShopHeading, ShopWrapper } from './ShopElements'

const Shop = () => {
    return (
        <ShopContainer id='shop'>
            <ShopHeading>Our store</ShopHeading>
            <ShopWrapper>
                <Iframe url="https://shop.spreadshirt.com/westindiadesigns"
                width="100%"
                height="1150px"
                frameBorder="0"
                position="relative"
                />
            </ShopWrapper>
            
        </ShopContainer>
    )
}

export default Shop
