import React, { useState, useEffect } from 'react'
import { NavHashLink as Link } from 'react-router-hash-link'
import { animateScroll as scroll } from 'react-scroll'
import {SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () =>{
        scroll.scrollToTop();
    };

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}> 
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarMenu scrollNav={scrollNav}>
                <SidebarLink  onClick={toggleHome}><Link smooth to='#home' style={{textDecoration:"none", color: "#fff"}}>Home</Link></SidebarLink>
                <SidebarLink ><Link smooth to='#shop' style={{textDecoration:"none", color: "#fff"}}>Products</Link></SidebarLink>
                <SidebarLink ><Link smooth to='#about' style={{textDecoration:"none", color: "#fff"}}>About</Link></SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute href='mailto:westindiadesigncompany@gmail.com' >Reach out</SidebarRoute>
            </SideBtnWrap>
        </SidebarContainer>
    )
}

export default Sidebar
