import React, { useState, useEffect } from 'react'
import {Nav, NavLink, NavIcon, Bars} from './NavbarElements'

const Navbar = ({toggle}) => {

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 120) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

   

    

    return (
        <div>
            <Nav scrollNav={scrollNav}>
                
                <NavLink to='/'>West India Design Co.</NavLink>
                <NavIcon onClick={toggle}>
                    <p>Menu</p>
                    <Bars />
                </NavIcon>
                
                
            </Nav>
        </div>
    );
}

export default Navbar
