import React, {useState, useEffect} from 'react'
import { NavHashLink as Link } from 'react-router-hash-link'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import {HeroContainer, HeroContent, HeroItems, HeroH1, HeroP, HeroBtn} from './HeroElements'

const Hero = () => {

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);


    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <HeroContainer>
            <Navbar toggle={toggle} scrollNav={scrollNav}/>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <HeroContent id='home'>
                <HeroItems>
                    <HeroH1>New designs available!</HeroH1>
                    <HeroP>Imagination | Illustration | Apparel</HeroP>
                    <HeroBtn ><Link smooth to='#shop' style={{textDecoration:"none", color: "#fff"}}>Browse products</Link></HeroBtn>
                </HeroItems>
            </HeroContent>
        </HeroContainer>
    )
}

export default Hero
